import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useStoresList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refStoresTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'Store name',
      sortable: true,
    },
    {
      key: 'departmentTitle',
      label: 'department',
      sortable: true,
    },
    {
      key: 'remarks',
      label: 'remarks',
      sortable: true,
    },
    {
      key: 'roles',
      label: 'managed by Roles',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'created on',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalStores = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref(route.query.status ?? null)
  const departmentFilter = ref([])
  if (Array.isArray(route.query.department)) {
    departmentFilter.value = route.query.department
  } else if (route.query.department) {
    departmentFilter.value = [route.query.department]
  }
  const toDeletedID = ref(null)
  const currentDataLength = ref(0)
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refStoresTable.value ? refStoresTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStores.value,
    }
  })

  const refetchData = () => {
    refStoresTable.value.refresh()
  }

  watch([searchQuery, statusFilter, departmentFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchStores = (ctx, callback) => {
    store
      .dispatch('app-stores/fetchStores', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        department: departmentFilter.value,
      },
      { root: true })
      .then(response => {
        const { stores, total } = response.data
        currentDataLength.value = stores.length
        callback(stores)
        totalStores.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              department: departmentFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteStore = () => {
    store
      .dispatch('app-stores/deleteStore', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStoreStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'danger'
    return 'primary'
  }

  return {
    fetchStores,
    tableColumns,
    perPage,
    currentPage,
    totalStores,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStoresTable,

    resolveStoreStatusVariant,
    refetchData,

    toDeletedID,
    deleteStore,
    currentDataLength,

    // Extra Filters
    statusFilter,
    departmentFilter,
  }
}
